<template>
  <div class="edit-gpt-container">
    <div class="section">
      <div class="container">
        <h1 class="title is-3">GPT Details</h1>
        <div class="box">
          <b-field label="GPT Name">
            <b-input v-model="gpt.name" placeholder="GPT Name"></b-input>
          </b-field>

          <b-field label="How">
            <b-input v-model="gpt.how" placeholder="How does it work?"></b-input>
          </b-field>

          <b-field label="How It Works">
            <b-textarea v-model="gpt.howItWorks" placeholder="Detailed explanation"></b-textarea>
          </b-field>

          <b-field label="Audience">
            <b-input v-model="gpt.audience" placeholder="Target Audience"></b-input>
          </b-field>

          <b-field label="Features">
            <div v-for="(feature, index) in gpt.features" :key="index">
              <b-checkbox v-model="feature.value">{{ feature.name }}</b-checkbox>
            </div>
          </b-field>

          <b-field label="Category">
            <b-select v-model="gpt.category" placeholder="Select a category">
              <!-- Options here -->
            </b-select>
          </b-field>
        </div>

        <b-field label="ChatGPT URL " name="chatUrl" class="field">
          <div>
            <b-input
              v-model="gpt.chatUrl"
              name="chatUrl"
              type="text"
              placeholder="ex: https://chat.openai.com/..."
              message="Enter the URL of your GPT chatbot"
            ></b-input>
            <b-tooltip
              type="is-dark"
              position="is-right"
              multilined
              label="Enter a the ChatGPT URL of this GPT chatbot"
            >
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </div>
        </b-field>

        <b-button type="is-dark" @click="saveGpt" :disabled="!isDataValid">Done!</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gpt: {
        name: "",
        how: "",
        howItWorks: "",
        audience: "",
        features: [
          { name: "Browse Web", value: false },
          { name: "Verify Knowledge Base", value: false },
          { name: "Realtime", value: false },
          { name: "Generate Image", value: false },
          // Add more features as needed
        ],
        category: "",
      },
      editKey: "",
    };
  },
  mounted() {
    this.editKey = this.$route.query.editKey;
    this.loadGpt();
  },
  computed: {
    isDataValid() {
      // Implement validation logic here
      return true; // Placeholder validation
    },
  },
  methods: {
    async loadGpt() {
      const gptId = this.$route.params.gptId;
      // Replace the next line with your actual API call to fetch the GPT data
      this.gpt = await window.API.getGpt(gptId);
    },
    async saveGpt() {
      try {
        const gptId = this.$route.params.gptId;
        // Replace the next line with your actual API call to update the GPT data
        await window.API.updateGpt(gptId, { ...this.gpt, editKey: this.editKey });
        this.$router.push("/gpts/" + this.gpt.id + "/saved"); // Redirect to the GPT URL
      } catch (error) {
        console.error("Error saving GPT:", error);
        // Handle error (e.g., show a notification to the user)
      }
    },
    // Define getGpt and updateGpt methods to interact with your API
  },
};
</script>

<style scoped>
.edit-gpt-container {
  /* Add your styles here */
}
</style>
